{
    "name": "boytacean-web",
    "version": "0.10.13",
    "description": "The web version of Boytacean",
    "repository": {
        "type": "git",
        "url": "git+https://github.com/joamag/boytacean.git"
    },
    "license": "Apache-2.0",
    "scripts": {
        "build": "parcel build index.html",
        "dev": "parcel index.html",
        "lint": "eslint .",
        "nodemon": "nodemon --exec \"parcel --no-cache index.html\"",
        "pretty": "prettier --config .prettierrc \"./**/*.{js,ts,tsx,json}\" --write",
        "start": "npm run build",
        "watch": "parcel watch index.html"
    },
    "source": "index.ts",
    "devDependencies": {
        "@parcel/transformer-typescript-tsc": "^2.12.0",
        "@types/react": "^18.3.4",
        "@types/react-dom": "^18.3.0",
        "@typescript-eslint/eslint-plugin": "^7.18.0",
        "@typescript-eslint/parser": "^7.18.0",
        "buffer": "^6.0.3",
        "emukit": "^0.10.13",
        "eslint": "^8.57.0",
        "eslint-plugin-import": "^2.29.1",
        "eslint-plugin-react-hooks": "^4.6.2",
        "jszip": "^3.10.1",
        "nodemon": "^3.1.4",
        "parcel": "^2.12.0",
        "prettier": "^3.3.3",
        "prettier-plugin-organize-imports": "^4.0.0",
        "process": "^0.11.10",
        "react": "^18.3.1",
        "react-dom": "^18.3.1",
        "recoil": "^0.7.7",
        "typescript": "^5.5.4",
        "webgl-plot": "^0.7.1"
    },
    "alias": {
        "process": {
            "global": "process"
        }
    }
}
